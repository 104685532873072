import React from "react";
import { Link } from "gatsby";
import "./Window.css";
import { StaticImage } from "gatsby-plugin-image";

const Window = ({ number, path, shouldNavigate }) => {
  return (
    <>
      {shouldNavigate ? (
        <Link to={path} className="enabled-link link">
          <div className="window">{number}</div>
          <StaticImage
            alt=""
            layout="fullWidth"
            src="../images/window-pic.jpg"
          />
        </Link>
      ) : (
        <Link
          to={path}
          className="disabled-link link"
          onClick={(e) => !shouldNavigate && e.preventDefault()}
        >
          <div className="window">{number}</div>
          <StaticImage
            alt=""
            layout="fullWidth"
            src="../images/window-pic.jpg"
          />
        </Link>
      )}
    </>
  );
};

export default Window;
