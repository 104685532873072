import * as React from "react";
import data from "../../data.json";
import Header from "../components/Header";
import Window from "../components/Window";
import { isDisabled } from "../utils/isDisabled";
import "@fontsource/montecarlo";
import "./index.css";
import { Helmet } from "react-helmet";

const IndexPage = () => {

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Adventi Kalendárium</title>
      </Helmet>
      <Header />
      <div className="window-wrapper">
        {data.map(({ name }, index) => {
          return (
            <Window
              shouldNavigate={isDisabled(name)}
              path={name}
              key={index}
              number={index + 1}
            />
          );
        })}
      </div>
    </>
  );
};

export default IndexPage;
